<template>
  <div class="flex-column" style="height: 80vh; justify-content: center">
    <video
      controls
      style="width: 100%"
      x5-playsinline="true"
      playsinline="true"
      webkit-playsinline="true"
      x-webkit-airplay="true"
      x5-video-orientation="portraint"
      autoplay
      preload="metadata"
    >
      >
      <source :src="data.src" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import { page } from 'project-common-mobile'
import { onMounted } from '@vue/runtime-core'
import { reactive } from '@vue/reactivity'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const data = reactive({
      src: '',
    })
    onMounted(
      page(() => {
        data.src = route.query.url
      }, data)
    )

    return { data }
  },
}
</script>

<style>
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
